// extracted by mini-css-extract-plugin
export var barContainer = "CountriesDashboardNavbar__barContainer__u4Rri";
export var barWrapper = "CountriesDashboardNavbar__barWrapper__UbP6T";
export var column = "CountriesDashboardNavbar__column__rZFjH";
export var flex = "CountriesDashboardNavbar__flex__LXQQa";
export var flexColumn = "CountriesDashboardNavbar__flexColumn__xWztY";
export var gap1 = "CountriesDashboardNavbar__gap1__SlEam";
export var gap2 = "CountriesDashboardNavbar__gap2__eISiD";
export var gap3 = "CountriesDashboardNavbar__gap3__R3j3D";
export var gap4 = "CountriesDashboardNavbar__gap4__RarNJ";
export var gap5 = "CountriesDashboardNavbar__gap5__z8aGe";
export var linkContainer = "CountriesDashboardNavbar__linkContainer__cl4oK";
export var linkItem = "CountriesDashboardNavbar__linkItem__XnG8X";
export var row = "CountriesDashboardNavbar__row___U95P";
export var underlinedLink = "CountriesDashboardNavbar__underlinedLink__n2PYx";